import React from "react";
import {motion} from 'framer-motion'

export const CustomeButton = ({
  btnText,
  btnMainClassName,
  buttonClassName,
  btnBackgroundColor,
  btnTextClassName,
  btnMarginTop,
  clickEvent,
  btnDisabled,
  icon,
  buttonId
}) => {
  return (
    <div className="" style={{ marginTop: btnMarginTop }} onClick={clickEvent}>
      <div className={`${btnMainClassName} border-[2px] border-[#a1a1a1] m-auto relative transform skew-x-[-20deg]`}
      >
        <button
          className={`w-full absolute h-full ${buttonClassName}`}
          style={{
            background: btnBackgroundColor,
          }}
          disabled = {btnDisabled}
          id={buttonId}
        >
          <p
            className={`${btnTextClassName} skew-x-[10deg] font-['archivo'] font-bold uppercase italic btn-text`}
            id={buttonId?buttonId+"_text":undefined}
          >
            {btnText}
          </p>
          {icon}
        </button>
      </div>
    </div>
  );
};
