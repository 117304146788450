import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useDisclosure,
  useEditable,
} from "@chakra-ui/react";
import { VideoModal } from "./VideoModal";
import { useSelector } from "react-redux";

export const VideoCard = ({
  firstName,
  lastName,
  videoUrl,
  videoStatus,
  token,
  isOpen,
  onOpen,
  onClose,
  handleClick,
}) => {
  
  useEffect(()=>{
  },[videoUrl])
  return (
    <div
      className="h-[550px] 2xl:h-[500px] border pt-2 pr-2 pl-2 pb-2 bg-opacity-20 backdrop-blur-[24.60px] cursor-pointer sm:pt-2 sm:pr-1 sm:pl-1 sm:pb-1 videoCardContainer"
      onClick={handleClick}
    >
      <p className="text-white font-['archivo'] text-nowrap uppercase leading-tight pb-2 sm:pb-1 sm:text-[15px]">
        {firstName + " " + lastName}
      </p>
      <div className="bg-black h-[94%]">
        {videoStatus != "done" ? (
          <div className="flex flex-col items-center justify-center h-full">
            <div className="spinner">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <p className="text-center font-bold text-white text-[15px] uppercase sm:text-[10px]">
              Your video will be <br /> available in few minutes
            </p>
          </div>
        ) : (
          <video className="w-full h-full sm:h-full object-cover">
            {" "}
             <source src={videoUrl} type="video/mp4" />
          </video>
        )}
      </div>
    </div>
  );
};
