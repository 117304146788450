import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Loading } from "./Loading";
import { CustomeButton } from "./CustomeButton";

export const SuccessModal = ({ isOpen, onClose, Counter, handleOk }) => {
  const { data, loading, error, status } = useSelector((state) => state.form);
  const [count, setCount] = useState(5);

  useEffect(() => {
    if (loading === false && status != null) {
      const timer = setTimeout(() => {
        setCount(count - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [loading, count]);
  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent background={"none"}>
          {/* <ModalCloseButton /> */}
          {loading ? (
            <ModalBody>
              <Loading />
            </ModalBody>
          ) : (
            <ModalBody>
              {error !== null ? (
                <>
                  <p className="font-['archivo'] italic uppercase leading-normal font-bold text-white text-center text-[20px]">
                    An Error <br /> ocuured
                  </p>
                  <p className="text-center mt-5 text-white text-[18px] font-normal font-['archivo]">
                    Please Try again later
                  </p>
                </>
              ) : (
                <div className="grid gap-4">
                  <p className="font-['archivo'] italic uppercase leading-normal font-bold text-white text-center text-[20px]">
                    FORM SUBMITTED <br /> SUCCESSFULLY
                  </p>
                  <img
                    src="/images/check.svg"
                    alt=""
                    className="block m-auto mt-5"
                  />
                  {/* <p className="text-center mt-5 text-zinc-400 text-[18px] font-normal font-['archivo]">
                screen will go back to home page in few seconds
              </p>
              <p className="text-white text-center font-bold text-[25px] font-['archivo'] italic">
                {count}
              </p> */}
                  <p className="text-[#B0B0B0] text-center  font-[600] leading-[24px] text-[18px]">
                    We will send your video on <br />
                    <span className="text-white font-bold">
                      WhatsApp
                    </span> and{" "}
                    <span className="text-white font-bold">Email</span> shortly.
                  </p>
                  <CustomeButton
                    btnText={"ok"}
                    btnMainClassName={
                      "w-[240px] sm:w-[150px] h-[72px] sm:h-[55px] mt-2"
                    }
                    btnBackgroundColor={"#DA241C"}
                    buttonClassName={
                      "bottom-[10px] sm:bottom-[5px] right-[10px] sm:right-[5px] flex items-center justify-center gap-[10px]"
                    }
                    btnTextClassName={
                      "text-2xl 2xl:text-xl text-[#01458E] text-white"
                    }
                    clickEvent={handleOk}
                    buttonId={"form_successfully_submitted"}
                  />
                </div>
              )}
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
