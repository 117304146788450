import { createSlice , createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import { GetAllVideosAPI } from "../../Hoc/constant";

let initialState = {
  data: [],
  loading: false,
  error: null,
};
export const fetchVideos = createAsyncThunk("AllVideos/fetchVideos", async () => {
    try {
      const response = await axios.get(GetAllVideosAPI);
      return response.data;
    } catch (error) {
      throw error;
    }
  });
export const AllVideosSlice = createSlice({
  name: "AllVideos",
  initialState,
  reducers: {
    getDataStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    getDataSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    getDataFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVideos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchVideos.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchVideos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const { getDataStart, getDataSuccess, getDataFailure } = AllVideosSlice.actions;
export default AllVideosSlice.reducer;