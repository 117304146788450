import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postFormSuccess, resetStatus } from "../features/form/formSlice";
import { postInitialState } from "../features/form/formAction";
import { SwifterLogo } from "../components/swifterLogo";
import { SuccessModal } from "../components/SuccessModal";
import { useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { CustomeButton } from "../components/CustomeButton";
import { TermAndConditionModal } from "../components/TermAndConditionModal";
import { motion } from "framer-motion";
import { Checkbox, CheckboxGroup } from "@chakra-ui/react";
import axios from "axios";
import { SendOTPAPI, VerifyOTPAPI } from "../Hoc/constant";

export const FormPage = () => {
  const [formView, setFormView] = useState(false);
  const [formData, setFormData] = useState({
    Email: "",
    FirstName: "",
    LastName: "",
    Gender: "",
    MobileNumber: "",
    FaceType: "",
    colorId: "",
    colorName: "",
    termsAndConditions: false,
    ImageName: "",
    ImageBase64: null,
  });
  const [otp, setOtp] = useState("");
  const [sent, setSent] = useState(false);
  const [display, setDisplay] = useState("hidden");
  const [count, setCount] = useState(30);
  const [otpError, setOtpError] = useState("");
  const [counter, setCounter] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [verified, setVerified] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [verifyMsg, setVerifyMsg] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const variants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.4, delay: 0.6, staggerChildren: 0.8 },
    },
    hidden: { opacity: 0, y: 20 },
  };

  // captured image
  const { image, gender, faceType, colorId, colorName } = useSelector(
    (state) => state.capture
  );
  // Form posting Details
  const { data, loading, error, status } = useSelector((state) => state.form);
  const handleFormPage = () => {
    setFormView(true);
  };
  const getFormData = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: newValue }));
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
      ...formData,
      ImageBase64: image,
      Gender: gender,
      FaceType: faceType, //update facetype here
      colorId: colorId, //update color id here
      colorName: colorName,
    };
    dispatch(postFormSuccess(updatedFormData));
    dispatch(postInitialState(updatedFormData));
    onOpen();
    // console.log(updatedFormData)
  };
  const handleOtpChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,5}$/.test(value)) {
      setOtp(value);
    }
  };
  //Send Otp Sending
  const handleSendOtp = async () => {
    setIsSending(true);
    try {
      const response = await axios.post(SendOTPAPI, null, {
        params: {
          mobileNumber: formData.MobileNumber,
        },
      });
      if (response.status === 200) {
        if(response.data.message === "OTP sent successfully"){
          setSent(true);
          setCount(30);
          setOtpError(``);
          setCounter(true);
          setDisplay("block");
          setVerified(false);
        }else{
          setOtpError(response.data.message)
        }
      } else {
        setOtpError("Error sending OTP");
      }
      // console.log(response);
    } catch (error) {
      console.error("Network error:", error);
      setOtpError("Please enter valid mobile number");
    } finally {
      setIsSending(false);
    }
  };
  // Resend otp timer

  useEffect(() => {
    let timer;
    if (sent && count > 0) {
      timer = setTimeout(() => {
        setCount(count - 1);
      }, 1000);
    } else if (count === 0) {
      setSent(false); // Allow resending OTP after countdown reaches zero
      setOtpError("");
      setCounter(false);
    }
    return () => clearTimeout(timer);
  }, [sent, count]);


  const handleVerify = async () => {
    setIsVerifying(true);
    try {
      const response = await axios.post(VerifyOTPAPI, null, {
        params: {
          mobileNumber: formData.MobileNumber,
          otp: otp,
        },
      });
      if (response.status === 200) {
        // console.log(response.data)
        if (response.data === "OK") {
          setVerified(true);
          setVerifyMsg("");
          setCounter(false);
        } else {
          setVerifyMsg(response.data);
          setVerified(false);
        }
      } else {
        console.error("Error sending OTP:", response.statusText);
        // setVerifyMsg("Please Enter Valid OTP");
      }
      // console.log(response);
    } catch (error) {
      console.error("Network error:", error);
      setVerifyMsg("Network Error");
    } finally {
      setIsVerifying(false);
    }
  };
  // useEffect(() => {
  //   if (status != null) {
  //     setFormData({
  //       Email: "",
  //       FirstName: "",
  //       LastName: "",
  //       Gender: "",
  //       MobileNumber: "",
  //       FaceType: "",
  //       ColorId: "",
  //       colorName: "",
  //       termsAndConditions: false,
  //       ImageName: "",
  //       ImageBase64: null,
  //     });
  //     const timer = setTimeout(() => {
  //       dispatch(resetStatus());
  //       navigate("/");
  //     }, 5000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [status]);
  const handleOk = () => {
    setFormData({
      Email: "",
      FirstName: "",
      LastName: "",
      Gender: "",
      MobileNumber: "",
      FaceType: "",
      ColorId: "",
      colorName: "",
      termsAndConditions: false,
      ImageName: "",
      ImageBase64: null,
    });
    dispatch(resetStatus());
    navigate("/");
  };
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      className="p-10 sm:p-5"
    >
      <SwifterLogo />
      {/*Processing Page*/}
      <div
        className="mt-10 sm:mt-8 h-fit gap-20 2xl:gap-10 lg:gap-20 sm:gap-10"
        style={{ display: formView ? "none" : "grid" }}
      >
        <p className="text-white text-center italic font-[700] leading-[28px] text-[20px]">
          YOUR VIDEO WILL BE PROCESSED SWIFTLY...
        </p>
        <div className="w-[8%] lg:w-[20%] sm:w-[50%] m-auto">
          <img src="/images/check.svg" alt="check" className="w-full" />
        </div>
        <p className="text-[#B0B0B0] text-center  font-[600] leading-[24px] text-[18px]">
          We will send your video on <br />
          <span className="text-white font-bold">WhatsApp</span> and{" "}
          <span className="text-white font-bold">Email</span> shortly.
        </p>
        {/* button */}
        <CustomeButton
          btnText={"Fill the Form"}
          btnMainClassName={"w-[240px] sm:w-[240px] h-[72px] sm:h-[65px]"}
          btnBackgroundColor={"#DA241C"}
          buttonClassName={
            "bottom-[10px] sm:bottom-[5px] right-[10px] sm:right-[5px] flex items-center justify-center gap-[10px]"
          }
          btnTextClassName={"text-2xl 2xl:text-xl text-[#01458E] text-white"}
          clickEvent={handleFormPage}
          buttonId={"fill_the_form"}
        />
      </div>
      {/* Form Page */}
      <motion.div
        initial="hidden"
        animate="visible"
        variants={variants}
        className="mt-10 2xl:mt-2 w-[30%] 2xl:w-[40%] lg:w-[70%] sm:w-full m-auto"
        style={{ display: formView ? "block" : "none" }}
      >
        <form onSubmit={handleFormSubmit}>
          <p id="form-lable">NAME</p>
          <input
            type="text"
            name="FirstName"
            placeholder="Name"
            id="form-input"
            className="w-full"
            onChange={getFormData}
            value={formData.FirstName}
            required
          />

          <p id="form-lable" className="mt-5">
            PHONE NUMBER
          </p>
          <div className="flex gap-2">
            <input
              type="text"
              name="MobileNumber"
              placeholder="Phone Number"
              id="form-input"
              // className="w-[70%]"
              className="w-[70%]"
              onChange={getFormData}
              value={formData.MobileNumber}
              maxLength={10}
              pattern="[0-9]*"
            />
            <button
              className="uppercase w-[29%] h-16 2xl:h-[50px] sm:h-[45px] bg-zinc-100 backdrop-blur-sm text-sky-800 text-[15px] font-extrabold font-['lato'] flex items-center justify-center"
              onClick={(e) => {
                e.preventDefault();
                handleSendOtp();
              }}
              disabled={sent && counter}
            >
              {isSending
                ? "please wait"
                : sent
                ? counter
                  ? `Resend OTP in ${count}`
                  : "Sent"
                : "send otp"}
            </button>
          </div>
          <p className="text-red-600 text-[15px]">{otpError}</p>
          {/* <p className="text-white text-[15px]">
            {counter ? `Resend OTP in ${count}` : ""}
          </p> */}
          <div className={display}>
            <p id="form-lable" className="mt-5">
              OTP
            </p>
            <div className="flex gap-2">
              <input
                type="number"
                placeholder="Enter Five Digit OTP"
                id="form-input"
                className="w-[70%]"
                onChange={handleOtpChange}
                maxLength={5}
                pattern="[0-9]*"
                value={otp}
              />
              <button
                className="uppercase w-[29%] h-16 2xl:h-[50px] sm:h-[45px] bg-sky-800 border border-sky-800 text-white text-[15px] font-extrabold font-['lato'] flex items-center justify-center"
                onClick={(e) => {
                  e.preventDefault();
                  handleVerify();
                }}
                disabled={verified === true ? true : false}
              >
                {isVerifying ? "please wait" : verified ? "verified" : "verify"}
              </button>
            </div>
            <p className="text-red-600 text-[15px]">{verifyMsg}</p>
          </div>
          <p id="form-lable" className="mt-5">
            EMAIL
          </p>
          <input
            type="email"
            name="Email"
            placeholder="Email"
            id="form-input"
            className="w-full"
            onChange={getFormData}
            value={formData.Email}
            required
          />
          <div className="mt-5 flex items-center justify-center gap-2">
            {/* <input
              type="checkbox"
              name="termsAndConditions"
              id=""
              onChange={getFormData}
              required
            /> */}
            <Checkbox
              size="lg"
              colorScheme="orange"
              required
              onChange={getFormData}
              name="termsAndConditions"
            ></Checkbox>
            {/* <p className="text-center text-white text-sm font-semibold font-['Lato'] underline leading-tight cursor-pointer">
              Terms and conditions
            </p> */}
            <TermAndConditionModal />
          </div>
          

          {/* Submit Button */}
          <div className="border-[2px] border-gray-400 w-[246px] sm:w-[200px] h-[85px] 2xl:h-[70px] sm:h-[60px] m-auto relative transform skew-x-[-20deg] mt-10">
            <button
              className={`w-full  ${
                verified ? "bg-[#DA241C]" : "bg-[#e26f69]"
              } absolute bottom-[10px] sm:bottom-[5px] right-[10px] sm:right-[5px] h-full`}
              type="submit"
              disabled={verified ? false : true}
              id="form_submitted"
            >
              <p className="skew-x-[10deg] text-white font-['archivo'] text-sky-800 font-bold uppercase italic leading-[33.45px] text-[25px] btn-text" id="form_submitted_text">
                {loading ? "Loading...." : "Submit"}
              </p>
            </button>
          </div>
        </form>
      </motion.div>
      <SuccessModal isOpen={isOpen} onClose={onClose} handleOk={handleOk} />
    </motion.div>
  );
};
