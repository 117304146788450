import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadingFaceDetect: false,
  error: null,
  FaceResponseData: null,
};
const faceDetectSlice = createSlice({
  name: "faceDetect",
  initialState,
  reducers: {
    uploadImageStart(state) {
      state.loadingFaceDetect = true;
      state.error = null;
    },
    uploadImageSuccess(state, action) {
      state.loadingFaceDetect = false;
      state.FaceResponseData = action.payload.status;
    },
    uploadImageFailure(state, action) {
      state.loadingFaceDetect = false;
      state.error = action.payload;
      state.FaceResponseData = "not found";
    },
  },
});
export const { uploadImageStart, uploadImageSuccess, uploadImageFailure } =
  faceDetectSlice.actions;
export default faceDetectSlice.reducer;
