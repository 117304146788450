import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {Helmet} from "react-helmet";
import { SwifterLogo } from "../components/swifterLogo";
import { CustomeButton } from "../components/CustomeButton";
import { GetTokenVideoAPI } from "../Hoc/constant";

export const Share = () => {
  const { token } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${GetTokenVideoAPI}${token}`)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="p-10 sm:p-5">
      {/* {
        !loading  || data.videoStatus == "done"? 
        (<Helmet>
        <meta property="og:title" content="Become A Swifter - Maruti Suzuki" />
        <meta property="og:description" content="Become A Swifter - Maruti Suzuki" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://artest.in/share/${token}`} />
        <meta property="og:image" content={data.posterUrl} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:height" content="1600" />
        <meta property="og:image:width" content="1080" />
      </Helmet> )
      :
      (<></>)
      } */}
      <SwifterLogo />
      {/* Video component */}
      <div className="w-[400px] 2xl:w-[700px] lg:h-[550px] 2xl:h-[400px] sm:w-full h-[560px] sm:h-[380px] border-opacity-50 m-auto mt-5">
        {loading || data.videoStatus != "done" ? (
          <div className="flex flex-col items-center justify-center w-[50%] h-full m-auto">
            <div className="spinner">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <p className="text-white font-bold text-2xl sm:text-2xl font-['archivo'] uppercase">
              Processing
            </p>
          </div>
        ) : (
          <>
            <p className="text-white text-center font-bold text-2xl sm:text-2xl font-['archivo-bold-italic'] uppercase">
              {data.firstName}
            </p>
            <iframe
              src={data.videoUrl}
              width={"100%"}
              height={"100%"}
              allow="fullscreen; autoplay"
              frameBorder={0}
              className="object-cover"
            ></iframe>
          </>
        )}
      </div>
      <CustomeButton
        btnText={"Become a Swifter"}
        btnMainClassName={"w-[250px] h-[50px] mt-10"}
        btnBackgroundColor={"red"}
        buttonClassName={"bottom-[5px] right-[5px]"}
        btnTextClassName={"text-white"}
        clickEvent={() => navigate("/capture")}
        buttonId={"cta_become_a_swifter_button"}
      />
    </div>
  );
};
