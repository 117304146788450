import "./App.css";
import { AllRoutes } from "./Routes/AllRoutes";
import { RouteswithAuth } from "./Routes/RouteswithAuth";
import { Navbar } from "./components/Navbar";


function App() {
  return (
    <div className="app relative">
      <Navbar />
      {/* <AllRoutes /> */}
      <RouteswithAuth/>
      {/* <div className="bg-black h-full opacity-80 w-full"></div> */}
      {/* <div className="absolute top-0 w-full">
      </div> */}
    </div>
  );
}

export default App;
