import React from "react";
import { SwifterLogo } from "./swifterLogo";
import { CustomeButton } from "./CustomeButton";
import { useNavigate } from "react-router-dom";

const imagesErrors = [
  {
    image: "/images/error/blur.jpg",
    title: "Image should not be blur",
  },
  {
    image: "/images/error/angle.jpg",
    title: "No side angles",
  },
  {
    image: "/images/error/exposed.jpg",
    title: "Image should not be over exposed",
  },
  {
    image: "/images/error/cover.jpg",
    title: "Don't cover your face",
  },
];

export const ImageError = () => {
  const navigate = useNavigate();
  return (
    <div className="p-10 2xl:p-2" id="selfie_error">
      <SwifterLogo />
      <p className="text-center text-white mt-1 text-xl sm:text-sm font-semibold font-['lato']">
        Oops! <br />
        Please reupload the image
      </p>
      <div className="p-5 mt-2 bg-neutral-900 border-2 border-white w-[30%] lg:w-[60%] m-auto sm:w-full">
        {/* images */}
        <div className="grid grid-cols-2 gap-3">
          {imagesErrors.map((errors, i) => (
            <div className="" key={i}>
              <img src={errors.image} alt="image" className="block m-auto"/>
              <p className="text-white text-center font-['lato'] font-semibold text-sm">
                {errors.title}
              </p>
            </div>
          ))}
        </div>
        {/* Ok Button */}
        <CustomeButton
          btnText={"OK"}
          btnMarginTop={"20px"}
          btnMainClassName={
            "border-[2px] border-[#9c9c9b] w-[175px] sm:h-[150px] h-[58px] sm:h-[40px] m-auto relative transform skew-x-[-20deg]"
          }
          btnBackgroundColor={"#E01F26"}
          buttonClassName={"right-[5px] bottom-[5px]"}
          btnTextClassName={"text-white text-xl font-bold"}
          clickEvent={() => navigate("/")}
          buttonId={"selfie_error_button"}
        />
      </div>
    </div>
  );
};
