import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { CustomeButton } from "./CustomeButton";

export const VideoModal = ({
  isOpen,
  onOpen,
  onClose,
  firstName,
  lastName,
  videoUrl,
  videoStatus,
}) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size={"full"}>
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px) hue-rotate(90deg)"
      />
      <ModalContent background={"none"} padding={"20px"}>
        <ModalHeader color={"white"}>{/* <SwifterLogo /> */}</ModalHeader>
        <ModalCloseButton color={"white"} />
        <ModalBody
          color={"white"}
          textAlign={"center"}
          border={"1px solid white"}
          margin={"auto"}
          w={{ lg: "100%", base: "100%" }}
          background={"black"}
          paddingTop={"50px"}
          className="VideoModalContainer"
        >
          <p className="text-3xl font-bold italic font-['archivo] uppercase">
            {firstName + " " + lastName}
          </p>
          <div className="mt-10 2xl:mt-5 h-[550px] 2xl:h-[70vh] sm:h-[400px] videoIframContainer">
            {videoStatus != "done" ? (
              <p className="text-center font-bold text-white uppercase">
              Your video will be <br /> available in few moments
            </p>
            ) : (
              <iframe
                src={videoUrl}
                width={"100%"}
                height={"100%"}
                allow="fullscreen; autoplay"
                frameBorder={0}
                className="object-cover"
              ></iframe>
            )}
          </div>
          <CustomeButton
            btnText={"Go back"}
            btnMainClassName={"w-[200px] mt-10 sm:h-[60px] h-[70px] 2xl:mt-5"}
            btnMarginTop={"10px"}
            btnBackgroundColor={"white"}
            buttonClassName={"bottom-[5px] right-[5px]"}
            btnTextClassName={"text-2xl sm:text-[20px] text-[#01458E]"}
            clickEvent={() => onClose()}
            // btnDisabled={cameraDetected === false ? true : cameraDetected === null ? true : false}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
