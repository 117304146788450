import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { SearchBar } from "../components/SearchBar";
import { VideoCard } from "../components/VideoCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchVideos } from "../features/AllVideos/AllVideosSlice";
import { VideoModal } from "../components/VideoModal";
import { Loading } from "../components/Loading";
import axios from "axios";
import "./Videos.css";
import { Pagination } from "../components/Pagination";
import { GetAllVideosAPI } from "../Hoc/constant";


export const Videos = () => {
  const [currentVideo, setCurrentVideo] = useState(null);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.AllvideosData);
  const [VideosData, setVideosData] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const intervalRef = useRef(null); // Ref for interval ID
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const maxVisiblePages = 5; // Maximum number of visible page buttons
  useEffect(() => {
    dispatch(fetchVideos());
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const res = await axios.get(GetAllVideosAPI);
        setVideosData(res.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    if (!isSearching) {
      intervalRef.current = setInterval(() => {
        fetchData();
        // forceUpdate()
      }, 10000);
    }
    fetchData();
    return () => clearInterval(intervalRef.current);
  }, [isSearching]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setIsSearching(!!e.target.value);
    const filteredVideos = data.filter((video) =>
      video.firstName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setVideosData(filteredVideos);
  };

  const handleVideo = (ele) => {
    onOpen();
    setCurrentVideo(ele);
  };
  const handleReset = () => {
    setIsSearching(!isSearching);
    setSearch("");
    setCurrentPage(1);
  };
  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = VideosData.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="bg-black pb-20">
      <div className="m-auto p-3 sm:pl-0 sm:pr-0 w-[80%] lg:w-[90%] sm:w-[95%]">
        <div className="flex pr-5 gap-7 justify-between">
          <div className="h-[110px] relative w-[25%] lg:w-[50%] sm:w-[40%] videoPageLogo">
            <img
              src="/images/swifter.png"
              alt="swifter"
              className="w-full absolute bottom-0"
            />
          </div>
          <div className="transform skew-x-[-25deg] border-[2px] h-[113px] w-[200px] pr-5 pt-2">
            <p className="transform skew-x-[25deg] text-right italic text-white text-6xl sm:text-5xl font-black font-['archivo'] uppercase leading-[52px]">
              {VideosData.length}
            </p>
            <p className="transform skew-x-[25deg] italic text-center text-white text-[30px] font-medium font-['archivo'] uppercase leading-loose">
              Swifters
            </p>
          </div>
        </div>
        <SearchBar
          handleChange={handleSearch}
          handleReset={handleReset}
          value={search}
        />
        {/* videos */}
        <div className="grid grid-cols-4 lg:grid-cols-3 2xl:grid-cols-3 sm:grid-cols-2 mt-5 gap-5 sm:gap-3 m-auto videoPageContainer">
          {currentItems.map((video, i) => {
            return (
              <VideoCard
                key={video.token}
                {...video}
                handleClick={() => handleVideo(video)}
              />
            );
          })}
        </div>
      </div>
      {/* Pagination */}
      <Pagination
        currentPage={currentPage}
        totalItems={VideosData.length}
        itemsPerPage={itemsPerPage}
        paginate={paginate}
        maxVisiblePages={maxVisiblePages}
      />
      <VideoModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        firstName={currentVideo?.firstName}
        lastName={currentVideo?.lastName}
        videoUrl={currentVideo?.videoUrl}
        videoStatus={currentVideo?.videoStatus}
      />
    </div>
  );
};
