import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { CustomeButton } from "./CustomeButton";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  XIcon,
} from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";

export const ShareModal = ({ isOpen, onOpen, onClose, videoUrl,shareUrl }) => {

  const websiteURL = 'https://swift.marutisuzuki.com';

  const sharedText = `Hey,\nCheck out my Swifter video.\n${shareUrl}\n\nNow you can become a Swifter. Visit the below website.\n${websiteURL}`

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size={"xl"}>
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px) hue-rotate(90deg)"
      />
      <ModalContent background={"none"} padding={"20px"}>
        <ModalHeader color={"white"}>{/* <SwifterLogo /> */}</ModalHeader>
        <ModalCloseButton color={"white"} />
        <ModalBody
          color={"white"}
          textAlign={"center"}
          border={"1px solid white"}
          margin={"auto"}
          w={{ lg: "100%", base: "100%" }}
          background={"black"}
          padding={"20px"}
        >
          <p className="text-xl font-bold italic font-['archivo] uppercase">
            Share Your video
          </p>

          <div className="mt-4 sm:h-[250px] grid grid-cols-2 gap-4">
            <FacebookShareButton
              url={shareUrl}
              hashtag={`#Swifting`}
              className="flex items-center gap-5"
            >
              <FacebookIcon size={32} round /> Facebook
            </FacebookShareButton>
            {/* <br /> */}
            <TwitterShareButton
              title={"Become a Swifter"}
              url={`\n${sharedText}`}
              hashtag={"#Swifting"}
              className="flex items-center gap-5"
            >
              <XIcon size={32} round />
              X
            </TwitterShareButton>

            {/* <br/> */}
            <WhatsappShareButton className="flex items-center gap-5" url={sharedText}>
              <WhatsappIcon size={32} round /> Whatsapp
            </WhatsappShareButton>
            {/* <br/> */}
            <EmailShareButton className="flex items-center gap-5"  body={sharedText} 	subject={`Become a Swifter`}>
              <EmailIcon size={32} round/> Email
            </EmailShareButton>
          </div>
          {/* <CustomeButton
            btnText={"Go back"}
            btnMainClassName={"w-[200px] sm:h-[60px] h-[70px] 2xl:mt-10"}
            btnMarginTop={"10px"}
            btnBackgroundColor={"white"}
            buttonClassName={"bottom-[5px] right-[5px]"}
            btnTextClassName={"text-2xl sm:text-[20px] text-[#01458E]"}
            clickEvent={() => onClose()}
            // btnDisabled={cameraDetected === false ? true : cameraDetected === null ? true : false}
          /> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
