import { CheckFaceDetectionAPI } from "../../Hoc/constant";
import {
  uploadImageStart,
  uploadImageSuccess,
  uploadImageFailure,
} from "./faceDetectSlice";

export const uploadImage = (imageData) => async (dispatch) => {
  const requestBody = {
    imageBase64: imageData,
  };
  dispatch(uploadImageStart());
  try {
    // Send the imageData in a POST request to your API endpoint
    const response = await fetch(CheckFaceDetectionAPI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody), // Assuming imageData is a base64 string
    });
    const data = await response.json();
    if (response.ok) {
      dispatch(uploadImageSuccess(data)); // Dispatch success action with response data
    } else {
      dispatch(uploadImageFailure("Upload failed")); // Dispatch failure action with error message
    }
  } catch (error) {
    dispatch(uploadImageFailure("An error occurred")); // Dispatch failure action for network or other errors
  }
};
