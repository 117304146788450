import React from "react";
import "./loading.css";

export const Loading = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full m-auto h-[80vh]">
      <div className="spinner">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <p className="text-white font-bold text-4xl sm:text-2xl font-['archivo'] uppercase">Processing</p>
    </div>
  );
};
