import React from "react";

export const SwifterLogo = ({ marginfromTop, AutoMargin }) => {
  return (
    <div
      className={`w-[20%] lg:w-[40%] sm:w-[50%] m-auto swifterlogo`}
    >
      <img src="/images/swifter.png" alt="swifter" className="w-full" />
    </div>
  );
};
