import { createSlice } from "@reduxjs/toolkit";


let initialState = {
  image: null,
  isAuthenticated :false,
  gender:"",
  faceType:"",
  colorId:"",
  colorName:""
};

export const captureSlice = createSlice({
  name: "Image",
  initialState,
  reducers: {
    captureImage: (state, action) => {
      state.image = action.payload;
      state.isAuthenticated = !!action.payload
    },
    getGender:(state,action)=>{
       state.gender = action.payload
    },
    getFaceType:(state,action) => {
      state.faceType = action.payload
    },
    getColorId : (state,action) => {
      state.colorId = action.payload
    },
    getColorName:(state,action) => {
      state.colorName = action.payload
    }
  },
});
export const { captureImage,getGender,getFaceType,getColorId,getColorName } = captureSlice.actions;
export default captureSlice.reducer;
