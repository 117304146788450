import React, { useEffect, useState } from "react";
import { SwifterLogo } from "../components/swifterLogo";
import { CustomeButton } from "../components/CustomeButton";
import { useParams } from "react-router-dom";
import axios from "axios";
// import useDownloader from "react-use-downloader";
import { ShareModal } from "../components/ShareModal";
import { useDisclosure } from "@chakra-ui/react";
import { GetTokenVideoAPI } from "../Hoc/constant";
export const VideoResult = () => {
  const { token } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const baseUrl = "https://swift.marutisuzuki.com/share/";
  // const {
  //   size,
  //   elapsed,
  //   percentage,
  //   download,
  //   cancel,
  //   error,
  //   isInProgress,
  // } = useDownloader();
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${GetTokenVideoAPI}${token}`
      )
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [downloading, setDownloading] = useState(false);
  const handleDownload = () => {
    setDownloading(true);
    try {
      fetch(data.videoUrl)
      .then(response => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'video.mp4'; // Replace with the desired filename for the downloaded file
          a.click();
          window.URL.revokeObjectURL(url);
          setDownloading(false);
        });
    } catch (error) {
      console.error(error);
      setDownloading(false);
    }
  };
  const fileUrl = `${data.videoUrl}`;
  const filename = `${data.firstName}`;
  const shareUrl = `${baseUrl}${data.token}`;

  return (
    <div className="p-10 sm:p-5">
      <SwifterLogo />
      {/* Video component */}
      <div className="w-[400px] 2xl:w-[700px] lg:h-[550px] 2xl:h-[400px] sm:w-full h-[560px] sm:h-[380px] border-opacity-50 m-auto mt-5">
        {loading || data.videoStatus != "done" ? (
          <div className="flex flex-col items-center justify-center w-[50%] h-full m-auto">
            <div className="spinner">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <p className="text-white font-bold text-2xl sm:text-2xl font-['archivo'] uppercase">
              Processing
            </p>
          </div>
        ) : (
          <>
            <p className="text-white text-center font-bold text-2xl sm:text-2xl font-['archivo-bold-italic'] uppercase">
              {data.firstName}
            </p>
            <iframe
              src={data.videoUrl}
              width={"100%"}
              height={"100%"}
              allow="fullscreen; autoplay"
              frameBorder={0}
              className="object-cover"
            ></iframe>
          </>
        )}
      </div>
      {/* Buttons */}
      <div className="mt-10 flex justify-around sm:grid sm:gap-5 items-center w-[50%] lg:w-[90%] lg:justify-center m-auto">
        {/* <div>
          <p className="text-white font-['archivo'] text-base font-extrabold italic underline uppercase cursor-pointer">
            Watch Trailer
          </p>
          <p className="text-white font-['archivo'] text-base font-extrabold italic underline uppercase cursor-pointer">
            Know More
          </p>
        </div> */}
        <div className="flex gap-5">
          <CustomeButton
            btnText={downloading ? "Wait..." : "Download"}
            btnMainClassName={"w-[134px] h-[35px]"}
            btnBackgroundColor={downloading ? "grey" : "white"}
            buttonClassName={"bottom-[5px] right-[5px]"}
            // clickEvent={() => download(fileUrl, filename)}
            clickEvent={() => handleDownload()}
            btnDisabled={downloading}
            buttonId={"video_download_button"}
          />
          <CustomeButton
            btnText={"Share"}
            btnMainClassName={"w-[134px] h-[35px]"}
            btnBackgroundColor={"#E01F26"}
            btnTextClassName={"text-white"}
            buttonClassName={"bottom-[5px] right-[5px]"}
            clickEvent={() => onOpen()}
            buttonId={"video_share_button"}
          />
        </div>
      </div>
      <ShareModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        videoUrl={data.videoUrl}
        shareUrl={shareUrl}
      />
    </div>
  );
};
