import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { SwifterLogo } from "./swifterLogo";
import { CustomeButton } from "./CustomeButton";

export const TermAndConditionModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <p className="text-left sm:text-center text-white text-sm font-semibold font-['lato'] leading-tight cursor-pointer">
        I agree to the storing & processing of my personal data by Maruti Suzuki
        as described in its
        <span id="privacy_policy" className="underline" onClick={() => onOpen()} style={{ color: "#f1f1f1" }}>
          {" "}
          Privacy Policy.
        </span>
      </p>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size={"full"}>
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent background={"none"} padding={"20px"}>

          <ModalCloseButton color={"white"} />
          <ModalHeader color={"white"}>
            <SwifterLogo />
          </ModalHeader>
          <ModalBody
            color={"white"}
            textAlign={"center"}
            border={"1px solid white"}
            display={"grid"}
            margin={"auto"}
            paddingLeft={"50px"}
            paddingRight={"50px"}
            paddingBottom={"50px"}
            paddingTop={"50px"}
            w={{ "2xl": "50%", lg: "50%", base: "100%" }}
          >
            <p className="text-xl font-bold italic ">Terms and Conditions</p>
            <div>{/* All conditions */}</div>
            <CustomeButton
              btnText={"ok"}
              btnMainClassName={
                "w-[170px] 2xl:w-[150px] h-[60px] 2xl:h-[50px] m-auto mt-60"
              }
              btnBackgroundColor={"#E01F26"}
              buttonClassName={
                "bottom-[5px] right-[5px] flex items-center justify-center gap-[10px]"
              }
              btnTextClassName={"text-2xl 2xl:text-xl text-[white]"}
              clickEvent={onClose}
              btnDisabled={"privacy_policy_close_button"}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
