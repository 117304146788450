import { useEffect, useState } from "react";
import { IoChevronBack } from "react-icons/io5";
import { IoChevronForwardOutline } from "react-icons/io5";
export const Pagination = ({
  currentPage,
  totalItems,
  itemsPerPage,
  paginate,
  maxVisiblePages,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };
    handleResize(); // Check initial width
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getVisiblePages = () => {
    if (isMobile) {
      return Math.min(3, Math.ceil(totalItems / itemsPerPage));
    }
    return maxVisiblePages;
  };

  const visiblePages = getVisiblePages();

  const getPageNumbers = () => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    if (totalPages <= visiblePages) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }
    const halfMaxPages = Math.floor(visiblePages / 2);
    let startPage = Math.max(currentPage - halfMaxPages, 1);
    let endPage = Math.min(currentPage + halfMaxPages, totalPages);

    if (endPage - startPage < visiblePages - 1) {
      if (currentPage <= halfMaxPages + 1) {
        endPage = visiblePages;
      } else {
        startPage = totalPages - visiblePages + 1;
      }
    }

    const pages = Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
    if (startPage > 1) {
      pages.unshift(1, "...");
    }
    if (endPage < totalPages) {
      pages.push("...", totalPages);
    }
    return pages;
  };

  const pages = getPageNumbers();

  return (
    <div className="flex justify-center mt-5 gap-2 sm:gap-1">
      <button
        onClick={() => paginate(currentPage - 1)}
        disabled={currentPage === 1}
        className="mr-3 px-4 py-2 bg-gray-800 text-white rounded-lg sm:mr-0 sm:w-[25px] sm:px-1 flex items-center justify-center"
      >
        <IoChevronBack />
      </button>
      {pages.map((page, index) => (
        <button
          key={index}
          onClick={() => {
            if (typeof page === "number") {
              paginate(page);
            }
          }}
          className={`px-4 py-2 rounded-lg ${
            currentPage === page
              ? "bg-red-500 text-white"
              : "bg-gray-800 text-white"
          }`}
        >
          {page}
        </button>
      ))}
      <button
        onClick={() => paginate(currentPage + 1)}
        disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
        className="ml-3 px-4 py-2 bg-gray-800 text-white rounded-lg sm:ml-0 sm:w-[25px] sm:px-1 flex items-center justify-center"
      >
        <IoChevronForwardOutline />
      </button>
    </div>
  );
};
