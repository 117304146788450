import React, { useEffect, useState } from "react";
import { CustomeButton } from "../components/CustomeButton";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { SwifterLogo } from "../components/swifterLogo";
import { TfiReload } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import {
  getColorId,
  getColorName,
  getFaceType,
  getGender,
} from "../features/captureSlice";
import { background } from "@chakra-ui/react";
import { Loading } from "../components/Loading";
import { ImageError } from "../components/ImageError";

const Gender = [
  {
    title: "Female",
    icon1: "/images/female.svg",
    icon2: "/images/female-light.svg",
    beard: false,
  },
  {
    title: "Male",
    icon1: "/images/male.svg",
    icon2: "/images/male-light.svg",
    beard: false,
  },
];

const carColors = [
  {
    colorName: "Metallic Sizzling Red",
    colorId: "6",
    category: "Monotone",
    dualtone: false,
    color: "linear-gradient(180deg, #DA241C 0%, #941F1A 100%)",
  },
  {
    colorName: "Prime Novel Orange",
    colorId: "5",
    category: "Monotone",
    dualtone: false,
    color: "#b33316",
  },
  {
    colorName: "Pearl Artic White",
    colorId: "8",
    category: "Monotone",
    dualtone: false,
    color: "linear-gradient(0deg, #BFBFBF 0%, #F5F5F5 100%)",
  },
  {
    colorName: "Prime Splendid Silver",
    colorId: "7",
    category: "Monotone",
    dualtone: false,
    color: "#737373",
  },
  {
    colorName: "Metallic Magma Gray",
    colorId: "4",
    category: "Monotone",
    dualtone: false,
    color: "#464848",
  },
  {
    colorName: "Prime Luster Blue",
    colorId: "2",
    category: "Monotone",
    dualtone: false,
    color: "#1f47c2",
  },
  {
    colorName: "Pearl Artic White with Midnight Black Roof",
    colorId: "9",
    category: "Dualtone",
    dualtone: true,
    color:
      "linear-gradient(90deg, #000 0%, #000 50%, #E5E4E2 50%, #E5E4E2 100%)",
  },
  {
    colorName: "Metallic Sizzling Red with Midnight Black Roof",
    colorId: "1",
    category: "Dualtone",
    dualtone: true,
    color:
      "linear-gradient(90deg, #1D1D1D 0%, #161616 50%, #EA4949 50%, #E01F26 100%)",
  },
  {
    colorName: "Prime Luster Blue with Midnight Black Roof",
    colorId: "3",
    category: "Dualtone",
    dualtone: true,
    color:
      "linear-gradient(90deg, #000 0%, #000 50%, #1F47C2 50%, #1F47C2 100%)",
  },
];

export const ImageCaptured = () => {
  const capturedImage = useSelector((state) => state.capture.image);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [optionsSelect, setOptionsSelect] = useState(false);
  const [selectedGender, setSelectedGender] = useState({
    title: "",
    icon1: "",
    icon2: "",
    beard: "",
  });
  const [beardChoose, setBeardChoose] = useState(false);
  const [beardOption, setBeardOption] = useState("");
  const [selectedColor, setSelectedColor] = useState({
    colorName: "",
    colorId: "",
    category: "",
    dualtone: null,
    color: "",
  });
  const [genderAlert, setGenderAlert] = useState({
    error: false,
    title: "",
  });
  const [beardAlert, setBeardAlert] = useState({
    error: false,
    title: "",
  });
  const [colorAlert, setColorAlert] = useState({
    error: false,
    title: "",
  });
  const { gender, faceType, colorId, colorName } = useSelector(
    (state) => state.capture
  );
  const { loadingFaceDetect, error, FaceResponseData } = useSelector(
    (state) => state.faceDetect
  );
  const variants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        delay: 0.6,
        when: "beforeChildren",
        staggerChildren: 0.8,
      },
    },
    hidden: {
      opacity: 0,
      y: 20,
      transition: {
        when: "afterChildren",
      },
    },
  };
  const handleRetake = () => {
    navigate("/capture");
  };
  useEffect(() => {
    if (capturedImage === null) {
      navigate("/capture");
    }
  });

  const handleGenderSelect = (ele) => {
    setSelectedGender(ele);
    dispatch(getGender(ele.title));
    setGenderAlert({ ...genderAlert, title: "", error: false });
    if (ele.title === "Female") {
      setBeardChoose(false);
      setBeardOption("");
      dispatch(getFaceType("1"));
    } else {
      setBeardChoose(true);
      dispatch(getFaceType(""));
    }
    // setButtonToggle(false);
  };

  const handleBeardSelectNo = () => {
    setBeardOption("No");
    dispatch(getFaceType("2"));
    setBeardAlert({ ...beardAlert, title: "", error: false });
  };
  const handleBeardSelectYes = () => {
    setBeardOption("Yes");
    dispatch(getFaceType("3"));
    setBeardAlert({ ...beardAlert, title: "", error: false });
  };
  const handleColorSelect = (ele) => {
    setSelectedColor(ele);
    dispatch(getColorId(ele.colorId));
    dispatch(getColorName(ele.colorName));
    setColorAlert({ ...colorAlert, title: "", error: false });
  };

  const handleConfirm = () => {
    if (gender === "") {
      setGenderAlert({
        ...genderAlert,
        title: "Please Select Gender",
        error: true,
      });
    } else if (faceType === "") {
      setBeardAlert({
        ...beardAlert,
        title: "Please select Face type",
        error: true,
      });
    } else if (colorId === "" && colorName === "") {
      setColorAlert({
        ...colorAlert,
        title: "Please Select Color",
        error: true,
      });
    } else {
      navigate("/form");
    }
  };

  if (loadingFaceDetect) {
    return <Loading />;
  }
  return FaceResponseData === "Success" ? (
    <>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={variants}
        className="p-1 lg:pt-10 sm:p-5"
        style={{
          display: optionsSelect ? "none" : "block",
        }}
      >
        <SwifterLogo />

        {/* Capture container */}
        <div className="mt-10 2xl:mt-1 sm:mt-5 w-[30%] lg:w-[80%]  sm:w-[90%] m-auto h-full lg:h-[50%] imageCaptured">
          <img
            src={capturedImage}
            alt="image"
            className="w-full lg:h-full sm:h-full object-cover sm:object-contain"
          />
        </div>
        <motion.p
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.4, delay: 0.4 }}
          variants={variants}
          className="text-[16px] font-bold mt-5 leading-normal italic text-center text-white font-['archivo'] uppercase lg:mt-10 sm:mt-5"
        >
          Do you want to proceed
        </motion.p>
        <div className="flex space-x-[10px] lg:justify-around items-center justify-between sm:justify-around mt-10 lg:mt-10 2xl:mt-4 w-[20%] 2xl:w-[22%] lg:w-[50%] m-auto sm:mt-5 sm:w-[90%]">
          <CustomeButton
            btnText={"retake"}
            btnMainClassName={
              "w-[170px] 2xl:w-[150px] h-[60px] 2xl:h-[50px] sm:w-[130px]"
            }
            btnBackgroundColor={"white"}
            buttonClassName={
              "bottom-[5px] right-[5px] flex items-center justify-center gap-[10px]"
            }
            btnTextClassName={
              "text-2xl 2xl:text-xl sm:text-[18px] text-[#01458E]"
            }
            icon={<TfiReload fontSize={"18px"} color="#01458E" />}
            clickEvent={handleRetake}
          />

          <CustomeButton
            btnText={"proceed"}
            btnMainClassName={
              "w-[170px] 2xl:w-[150px] h-[60px] 2xl:h-[50px] sm:w-[130px]"
            }
            btnBackgroundColor={"#DA241C"}
            buttonClassName={
              "bottom-[5px] right-[5px] flex items-center justify-center gap-[10px]"
            }
            btnTextClassName={
              "text-2xl 2xl:text-xl text-[#01458E] text-white  sm:text-[18px]"
            }
            clickEvent={() => setOptionsSelect(true)}
            // btnDisabled={buttonToggle}
          />
        </div>
      </motion.div>
      {/* more information div */}
      <motion.div
        initial="hidden"
        animate="visible"
        variants={variants}
        className="p-1 lg:pt-10 sm:p-5"
        style={{
          display: optionsSelect ? "block" : "none",
        }}
      >
        <SwifterLogo />
        <p className="text-[16px] mt-5 font-bold leading-normal italic text-center text-white font-['archivo'] uppercase lg:mt-10 sm:mt-2">
          Select gender
        </p>
        <div
          className="w-[15%] lg:w-[30%] space-x-[5px] justify-center gap-5 items-center sm:w-[60%] m-auto mt-3 mb-3 "
          style={{ display: "flex" }}
        >
          {Gender.map((ele, i) => {
            return (
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                key={i}
                className={
                  selectedGender.title === ele.title
                    ? "w-[122px] 2xl:w-[100px] h-[122px] 2xl:h-[100px] rounded-[50%] border-2 border-white border-opacity-70 p-[5px] lg:p-[3px] 2xl:p-[3px] sm:p-[3px]"
                    : "px-[5px]"
                }
                onClick={() => handleGenderSelect(ele)}
              >
                <div
                  className="cursor-pointer w-[108px] 2xl:w-[90px] m-auto border h-[108px] 2xl:h-[90px] flex items-center justify-center border-[#5B5B5A] rounded-[50%]"
                  style={{
                    background:
                      selectedGender.title === ele.title
                        ? "linear-gradient(90deg, #FFFFFF 0%, #CDCDCD 100%)"
                        : "linear-gradient(90deg, #373737 0%, #5B5B5B 100%)",
                  }}
                >
                  <img
                    src={
                      selectedGender.title === ele.title ? ele.icon1 : ele.icon2
                    }
                    alt="icon"
                    className="block m-auto w-[60%]"
                  />
                </div>
              </motion.div>
            );
          })}
        </div>
        {/* Beard choose */}
        <div
          className={`relative ${
            beardChoose ? "opacity-[100%]" : "opacity-[40%]"
          }`}
        >
          <div
            className={`border w-[30%] lg:w-[50%] sm:w-[80%] m-auto ${"border-[#3A3A3A]"}`}
          />
          <p
            className="text-[16px] mt-5 font-bold leading-normal italic text-center  font-['archivo'] uppercase lg:mt-10 sm:mt-2"
            style={{ color: beardChoose ? "white" : "#808080" }}
          >
            Choose Look
          </p>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={variants}
            className="w-[25%] lg:w-[60%] justify-center relative gap-4 sm:w-[90%] m-auto mt-2 2xl:mt-1"
            style={{ display: beardChoose ? "flex" : "flex" }}
          >
            {/* No Button */}
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={
                "w-[100px] h-[100px] rounded-[50%] border-opacity-70 p-[3px]"
              }
              style={{ border: beardOption === "No" ? "2px solid white" : "" }}
              onClick={handleBeardSelectNo}
            >
              <div
                className="cursor-pointer w-[90px] h-[90px] m-auto border flex items-center justify-center border-[#5B5B5A] rounded-[50%]"
                style={{
                  background:
                    beardOption === "No"
                      ? "linear-gradient(90deg, #FFF 0%, #CDCDCD 100%)"
                      : "linear-gradient(90deg, #373737 0%, #5B5B5B 100%)",
                }}
              >
                <img
                  src={`/images/${
                    beardOption === "No"
                      ? "no-beard-black.svg"
                      : "no-beard-gray.svg"
                  }`}
                  alt="image"
                  className="w-[65%]"
                />
              </div>
            </motion.div>
            {/* yes button */}
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={
                "w-[100px] h-[100px] rounded-[50%] border-opacity-70 p-[3px]"
              }
              style={{ border: beardOption === "Yes" ? "2px solid white" : "" }}
              onClick={handleBeardSelectYes}
            >
              <div
                className="cursor-pointer w-[90px] h-[90px] m-auto border flex items-center justify-center border-[#5B5B5A] rounded-[50%]"
                style={{
                  background:
                    beardOption === "Yes"
                      ? "linear-gradient(90deg, #FFF 0%, #CDCDCD 100%)"
                      : "linear-gradient(90deg, #373737 0%, #5B5B5B 100%)",
                }}
              >
                <img
                  src={`/images/${
                    beardOption === "Yes" ? "beard-black.svg" : "beard-gray.svg"
                  }`}
                  alt="image"
                  className="w-[65%]"
                />
              </div>
            </motion.div>
          </motion.div>
          <div
            className={`absolute w-full top-0 h-full ${
              selectedGender.title === "Female" ? "block" : "hidden"
            }`}
          ></div>
        </div>
        {/* Color Choose */}

        <motion.div initial="hidden" animate="visible" variants={variants}>
          <div
            className={`border w-[30%] lg:w-[50%] sm:w-[80%] m-auto mt-1 ${"border-[#3A3A3A]"}`}
          />
          <p className="text-[16px] mt-5 font-bold leading-normal italic text-center text-white font-['archivo'] uppercase lg:mt-10 sm:mt-2">
            Select your swift colour
          </p>
          <p className="text-center text-white text-sm font-extralight font-['archivo'] uppercase leading-relaxed">
            {selectedColor.colorName}
          </p>
          {/* colors */}
          <div className="flex justify-between mt-5 sm:mt-2 w-[20%] lg:w-[40%] sm:w-[90%] m-auto">
            {carColors.map((ele, i) => {
              if (ele.dualtone === false) {
                return (
                  <div
                    className="w-[36px] h-[36px] rounded-[50%] cursor-pointer"
                    style={{
                      background: ele.color,
                      border:
                        selectedColor.colorName === ele.colorName
                          ? "2px solid white"
                          : "1px solid #5B5B5A",
                    }}
                    key={i}
                    onClick={() => handleColorSelect(ele)}
                  ></div>
                );
              }
            })}
          </div>
          <div className="flex justify-center gap-8 mt-5 sm:mt-3 w-[20%] lg:w-[60%] sm:w-[90%] sm:gap-7 m-auto">
            {carColors.map((ele, i) => {
              if (ele.dualtone === true) {
                return (
                  <div
                    className="w-[36px] h-[36px] rounded-[50%] cursor-pointer"
                    style={{
                      background: ele.color,
                      border:
                        selectedColor.colorName === ele.colorName
                          ? "2px solid white"
                          : "1px solid #5B5B5A",
                    }}
                    key={i}
                    onClick={() => handleColorSelect(ele)}
                  ></div>
                );
              }
            })}
          </div>
        </motion.div>
        <CustomeButton
          btnText={"confirm"}
          btnMainClassName={
            "w-[170px] 2xl:w-[150px] h-[60px] 2xl:h-[50px] sm:w-[130px] mt-5"
          }
          //   btnBackgroundColor={
          //     gender === "" &&
          //     faceType === "" &&
          //     colorId === "" &&
          //     colorName === ""
          //       ? "#FFAEAA"
          //       : "#DA241C"
          //   }
          btnBackgroundColor={"#DA241C"}
          buttonClassName={
            "bottom-[5px] right-[5px] flex items-center justify-center gap-[10px]"
          }
          btnTextClassName={
            "text-2xl 2xl:text-xl text-[#01458E] text-white  sm:text-[18px]"
          }
          clickEvent={handleConfirm}
          buttonId={"user_type_confirm"}
        />
        <p className="text-center mt-2 text-[#da241c]">
          {genderAlert.error ? genderAlert.title : ""}
        </p>
        <p className="text-center mt-2 text-[#da241c]">
          {beardAlert.error ? beardAlert.title : ""}
        </p>
        <p className="text-center mt-2 text-[#da241c]">
          {colorAlert.error ? colorAlert.title : ""}
        </p>
      </motion.div>
    </>
  ) : (
    <ImageError />
  );
};
