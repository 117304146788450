import React from "react";
import { Input, InputGroup, InputRightElement, Button } from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/react";
import { TfiReload } from "react-icons/tfi";
import { FiSearch } from "react-icons/fi";
export const SearchBar = ({ handleChange, handleReset,value }) => {
  return (
    <div className="mt-5 flex items-center gap-3 w-[30%] lg:w-[50%] sm:w-full">
      <InputGroup size="lg" backgroundColor={"#141414cc"} border={"#ffffff66"}>
        <Input
          pr="4.5rem"
          type={"text"}
          placeholder="Search"
          color={"white"}
          onChange={handleChange}
          value = {value}
        />
        <InputRightElement width="3rem">
          <IconButton
            background={"#3C3C3C"}
            colorScheme="white"
            aria-label="Reload"
            icon={<FiSearch color="white" />}
          />
        </InputRightElement>
      </InputGroup>
      <Button
        background={"#3C3C3C"}
        colorScheme="white"
        aria-label="Reload"
        onClick={handleReset}
      >
        <TfiReload color="white" fontSize={"20px"} fontWeight={"bold"} />
      </Button>
    </div>
  );
};
