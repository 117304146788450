import { configureStore } from "@reduxjs/toolkit";
import captureReducer from "../features/captureSlice";
import formReducer from "../features/form/formSlice";
import faceDetectReducer from "../features/faceDetection/faceDetectSlice";
import AllVideosReducer from "../features/AllVideos/AllVideosSlice";
export const store = configureStore({
  reducer: {
    capture: captureReducer,
    form: formReducer,
    faceDetect: faceDetectReducer,
    AllvideosData: AllVideosReducer,
  },
});
