import React, { useContext, useState } from "react";
import { Input, InputGroup, InputRightElement, Button } from "@chakra-ui/react";
import { AuthContext } from "../Hoc/authContext";
import { useLocation, useNavigate } from "react-router-dom";


export const Login = () => {
    const {loginUser} = useContext(AuthContext);
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [username, setusername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState('');
 const navigate = useNavigate("/")
 const currentRoute = location.pathname
  const handleLogin = () => {
    setError('');
    const isAuthenticated = loginUser(username, password,currentRoute);
    if (!isAuthenticated) {
      setError('Invalid credentials. Please try again.');
    }
  };

  const handleClick = () => {
    setShow(!show);
  };
  return (
    <div className="border w-[50%] m-auto mt-20 bg-white p-20 rounded-[20px] sm:w-full sm:p-2">
      <p className="text-4xl font-bold text-center font-['archivo'] mb-5 sm:text-2xl">
        Sign In
      </p>
      <Input
        pr="4.5rem"
        type={"text"}
        placeholder="Username"
        value={username}
        onChange={(e) => setusername(e.target.value)}
      />
      <InputGroup size="md" marginTop={"10px"}>
        <Input
          pr="4.5rem"
          type={show ? "text" : "password"}
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" onClick={handleClick}>
            {show ? "Hide" : "Show"}
          </Button>
        </InputRightElement>
      </InputGroup>
      <Button marginTop={"10px"} width={"100%"} colorScheme="blue" onClick={handleLogin}>
        Sign In
      </Button>
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
};
