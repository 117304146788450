import { SubmitVideoRequestAPI } from "../../Hoc/constant";
import {
  postFormFailure,
  postFormStart,
  postFormStatus,
  postFormSubmit,
  postFormSuccess,
  resetForm,
} from "./formSlice";

export const postInitialState = (form) => async (dispatch) => {
  dispatch(postFormStart());
  try {
    const response = await fetch(SubmitVideoRequestAPI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    });
    const responseData = await response.json();
    if (response.ok) {
    //   console.log(responseData);
      dispatch(postFormStatus(responseData));
    } else {
      console.log("error");
      dispatch(postFormFailure("error"));
    }
    dispatch(postFormStart(responseData));
    // console.log("Data posted successfully:", responseData);
  } catch (error) {
    dispatch(postFormFailure("Error"));
    // console.error("Error posting data to the API:", error.message);
  }
  dispatch(resetForm());
};
