import React from "react";
import { SwifterLogo } from "../components/swifterLogo";
import { CustomeButton } from "../components/CustomeButton";
import { useNavigate } from "react-router-dom";
import { motion, transform } from "framer-motion";
import { transition } from "@chakra-ui/react";
import { IoIosArrowBack } from "react-icons/io";
import { useState } from "react";

// New
// const variants = {
//   visible: {
//     opacity: 1,
//     y: 0,
//     transition: {
//       duration: 0.4,
//       delay: 0.6,
//       when: "beforeChildren",
//       staggerChildren: 0.8,
//     },
//   },
//   hidden: {
//     opacity: 0,
//     y: 20,
//     transition: {
//       when: "afterChildren",
//     },
//   },
// };

// export const Home = () => {
//   const navigate = useNavigate();
//   return (
//     <motion.div
//       animate="visible"
//       transition={{ duration: 0.4, delay: 0.4 }}
//       variants={variants}
//       className="p-5 sm:p-2"
//     >
//       <SwifterLogo />

//       <div className="mt-5 w-[40%] lg:w-[90%] 2xl:w-[40%] sm:w-full m-auto relative sm:mt-[30%]">
//         <img src="/images/bg.jpg" alt="" className="w-full" />
//         <div className="absolute w-full h-full top-0 flex items-center justify-center overlay-colors-black"></div>
//         <div className="absolute top-0 flex flex-col items-center justify-start mt-8 sm:mt-4 md:p-[30px] sm:p-[30px] w-full h-full">
//           <p className="text-4xl sm:text-2xl font-['archivo'] font-bold opacity-[100%]  text-white uppercase flex items-center gap-2 italic">
//             Take a{" "}
//             <div className=" bg-[#da241c] pr-2 pl-2 pb-2 pt-2 transform skew-x-[-20deg]">
//               <p className="transform skew-x-[10deg] italic">selfie</p>
//             </div>{" "}
//             and
//           </p>
//           <p className="text-4xl sm:text-2xl ml-[102px] sm:ml-[65px] font-['archivo'] font-bold opacity-[100%] text-white uppercase flex items-center gap-3 italic">
//             Become a{" "}
//             <div className="bg-white pr-2 pl-2 pb-2 pt-2 transform skew-x-[-20deg]">
//               <p className="transform skew-x-[10deg] italic text-black">
//                 swifter
//               </p>
//             </div>
//           </p>
//         </div>
//       </div>
//       <CustomeButton
//         btnMainClassName={"w-[180px] h-[52px] absolute bottom-5"}
//         btnText={"Take a selfie"}
//         clickEvent={() => navigate("/capture")}
//         btnBackgroundColor={"#E01F26"}
//         buttonClassName={"right-[5px] bottom-[5px]"}
//         btnTextClassName={"text-white text-xl font-bold"}
//       />
//     </motion.div>
//   );
// };

// Old
const videoTimers = [
  {
    image: "/images/tech.png",
    title: "Tech",
    videoTime: 236,
  },
  {
    image: "/images/performance.png",
    title: "PERFORMANCE",
    videoTime: 36,
  },
  {
    image: "/images/design.png",
    title: "Design",
    videoTime: 149,
  },

];

// framer motion
const variants = {
  visible: {
    opacity: 1 ,
    y: 0 ,
    transition:{duration: 0.4,
      delay:0.6,
      when:"beforeChildren",
      staggerChildren: 0.8}
  },
  hidden: { opacity: 0, y: 20,
    transition:{
      when:"afterChildren",
    }
   },
}
let player;
let startTimeInterval;
export const Home = () => {
  const navigate = useNavigate();
  const [startTime, setStartTime] = useState("");
  const [videoShow, setShowVideo] = useState(false);
  const [videoAutoplay, setVideoAutoplay] = useState(0);
  const [overlayShow, setOverlayShow] = useState(false)
  const videoID = "xfXhLsUkWg4"; //Replace the video id here

  const handleVideoShow = (videoTime) => {
    // setVideoAutoplay(1);
    console.log(videoTime)
    // player.seekTo(videoTime);
    setOverlayShow(false)
    goTo(videoTime)

    // setShowVideo(true);
  };
  const handleback = () => {
    setVideoAutoplay(0);
    // setShowVideo(false);
  };

      var tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      document.head.appendChild(tag);

      window.onYouTubeIframeAPIReady = () =>{
        player = new window.YT.Player("player", {
          height: "390",
          width: "640",
          videoId: "40GmmCxiHAs",
          playerVars: {
            playsinline: 1,
            controls: 0,
            disablekb: 1,
            fs: 0,
            rel: 0,
            modestbranding: 1,
            playlist: "40GmmCxiHAs",
            autohide: 1,
            showinfo: 0,
            listype: "playlist",
            iv_load_policy: 3,
          },
          events: {
            'onReady': onPlayerReady,
          }
        });
        function onPlayerReady(event) {
          startTimeInterval()
        }
        console.log(player);
        //
        let currentTime;
        let currentTimeInterval

        startTimeInterval = () => {
          currentTimeInterval =  setInterval(() => {
            currentTime = Math.floor(player.getCurrentTime());
            console.log(currentTime);
            pause();

          }, 1000);
        }


        function stopTimeInterval(){
          clearInterval(currentTimeInterval)
        }


        function pause() {
          if (currentTime === 35) {
            player.pauseVideo();
            displayOverlay();
            stopTimeInterval();
          } else if (currentTime === 148) {
            player.pauseVideo();
            displayOverlay();
            stopTimeInterval();
          } else if (currentTime === 235) {
            player.pauseVideo();
            displayOverlay();
            stopTimeInterval();
          } else if (currentTime === 312) {
            player.pauseVideo();
            displayOverlay();
            stopTimeInterval();
          }
        }

        function displayOverlay() {
          setOverlayShow(true);
        }
      };

      function goTo(time) {
        player.seekTo(time);
        player.playVideo();
        startTimeInterval()
      }

      // function performance() {
      //   player.seekTo(144);
      // }

      // function design() {

      //   // console.log(player);
      //   player.seekTo(232);
      // }

      // function tech() {
      //   player.seekTo(310);
      // }




  return (
    <motion.div initial="hidden"
    animate="visible"
    transition={{duration: 0.4, delay:0.4}}
      variants={variants}  className="p-2 2xl:p-1 lg:p-5 sm:p-3 h-full">
      <div className="relative">

        <div id="player" className={` w-[1200px] 2xl:w-[1000px] lg:w-[700px] sm:w-full h-[550px] lg:h-[340px] sm:h-[220px] 2xl:h-[380px] m-auto overflow-hidden relative`}
          // style={{
          //   display: videoShow ? "block" : "none",
          // }}
        >
          {/* <iframe
            src={`https://www.youtube.com/embed/${videoID}?autoplay=${videoAutoplay}&rel=0&controls=0&showinfo=0&loop=1&playlist=${videoID}&start=${startTime}`}
            frameBorder="0"
            allowFullScreen
            width="100%"
            height="100%"
            autoPlay
            className="transform scale-[1.5]"
            allow="autoplay"
          ></iframe> */}
          <p
            className="text-white flex items-center gap-[2px] font-bold font-['archivo'] italic cursor-pointer absolute top-1 border-b ml-2"
            onClick={handleback}
          >
            <IoIosArrowBack fontWeight={"bold"} fontSize={"22px"} /> GO BACK
          </p>
        </div>
        <div id="overlay"
          className="absolute top-0 left-[50%] translate-x-[-50%] h-[100%]  w-[1200px] 2xl:w-[1000px] lg:w-[700px] sm:w-full  m-auto pt-0 2xl:pt-5 lg:pt-0 sm:mt-0"
          style={{
            display: overlayShow ? "block" : "none",
          }}
        >

          <div className="grid grid-cols-3 justify-around items-center  h-[85%]">
            {videoTimers.map((ele, i) => (
              <div
                key={i}
                className="flex flex-col cursor-pointer w-[100%] h-[100%] relative "
                onClick={() => handleVideoShow(ele.videoTime)}
              >
                <p className="absolute left-[50%] translate-x-[-50%] bottom-4 italic text-center text-slate-50 text-[21.09px] lg:text-[15px] sm:text-[10px] font-black font-['archivo'] uppercase leading-[18.08px]">
                  {ele.title}
                </p>
                {/* <div className="lg:w-[200px] h-[320px] 2xl:w-[280px] sm:w-fit border border-sky-500 ">
                  <img src={ele.image} alt={ele.title} className="block m-auto" />
                </div> */}
                {/* <button className="bg-white w-[218px] lg:w-[180px] sm:w-[100px] sm:h-[30px] h-[53px] lg:h-[40px] m-auto transform skew-x-[-25deg]">
                  <p className=" transform skew-x-[25deg] italic text-center text-sky-800 text-[21.09px] lg:text-[15px] sm:text-[10px] font-black font-['archivo'] uppercase leading-[18.08px]">
                    {ele.title}
                  </p>
                </button> */}
              </div>
            ))}
          </div>
          <p className="text-center text-slate-50 text-[48px] h-[15%] 2xl:text-[56px] sm:text-[18px] lg:text-[36px] italic font-black font-['archivo'] uppercase">
            CHOOSE YOUR SwIFTER
          </p>
        </div>
      </div>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={variants}
      className="flex justify-between lg:flex-col lg:gap-10 sm:grid items-center w-[1200px] 2xl:w-[1000px] lg:w-[100%] sm:w-full m-auto mt-5 relative">
        <motion.div className="sm:w-[70%] 2xl:w-[25%] lg:w-[50%] m-auto lg:mt-20 sm:mt-2">
          <img src="/images/swifter-new.png" alt="logo" className="2xl:w-full" />
        </motion.div>
        {/* yt video */}
        <motion.div className="sm:mt-2">
          <div className="w-[600px] h-[200px] lg:w-full lg:m-auto sm:w-[100%] sm:h-fit sm:m-auto" >
          <img src="/images/bg.jpg" alt="logo" className="2xl:w-full object-center object-cover" />
          </div>
          <CustomeButton
            btnMainClassName={"w-[180px] h-[52px] absolute bottom-5"}
            btnText={"Take a selfie"}
            clickEvent={() => navigate("/capture")}
            btnBackgroundColor={"#E01F26"}
            buttonClassName={"right-[5px] bottom-[5px]"}
            btnTextClassName={"text-white text-xl font-bold"}
            buttonId={"cta_take_selfie"}
          />
        </motion.div>
      </motion.div>
    </motion.div>
  );
};
