import React from 'react'

export const Navbar = () => {
  return (
    <div className='h-[64px] 2xl:h-[54px] flex items-center pl-10 sm:pl-4 bg-white w-full'>
        <div className='sm:w-[50%]'>
            <img src="/images/Logo.svg" alt="" className='w-full'/>
        </div>
    </div>
  )
}
