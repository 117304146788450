import { createSlice } from "@reduxjs/toolkit";


let initialState = {
  data: {
    Email: null,
    FirstName: null,
    LastName: null,
    Gender: null,
    MobileNumber: null,
    FaceType: null,
    colorId:null,
    ImageName: null,
    termsAndConditions: false,
    ImageBase64: null,
  },
  loading: false,
  error: null,
  status: null,
};

export const FormSlice = createSlice({
  name: "UserForm",
  initialState,
  reducers: {
    postFormStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    postFormSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    postFormStatus: (state, action) => {
      state.status = action.payload.status;
      state.loading = false;
    },
    postFormFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetForm: (state) => {
      // Reset the form state to initial values
      // state.data = initialState.data;
      state.loading = false;
      // state.error = null;
    },
    resetStatus: (state) => {
      state.status = null;
      state.error = null;
    },
  },
});
export const {
  postFormStart,
  postFormSuccess,
  postFormSubmit,
  postFormStatus,
  postFormFailure,
  resetForm,
  resetStatus,
  refreshComponent,
  resetRefreshState,
} = FormSlice.actions;

export default FormSlice.reducer;
