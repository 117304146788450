import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { captureImage } from "../features/captureSlice";
import { uploadImage } from "../features/faceDetection/faceDetectAction";
import { SwifterLogo } from "../components/swifterLogo";
import { CustomeButton } from "../components/CustomeButton";
import { motion } from "framer-motion";
import { Camera } from "react-camera-pro";
export const Capture = () => {
  const webcamRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cameraDetected, setCameraDetected] = useState(null); // Default to true

  useEffect(() => {
    let mounted = true;
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => {
        if (mounted) {
          setCameraDetected(true);
        }
      })
      .catch(() => {
        if (mounted) {
          setCameraDetected(false);
        }
      });
    return () => {
      mounted = false;
    };
  }, [webcamRef]);

  const handleClick = useCallback(() => {
    const capturedImage = webcamRef.current.getScreenshot();
    if (capturedImage) {
      dispatch(captureImage(capturedImage));
      dispatch(uploadImage(capturedImage));
    } else {
      console.log("Image Not Captured");
    }
    navigate("/imageCaptured");
  }, [webcamRef]);

  const videoConstraints = {
    width: 420,
    height: 420,
    facingMode: "user",
  };

  const variants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4,
        delay: 0.6,
        when: "beforeChildren",
        staggerChildren: 0.8,
      },
    },
    hidden: {
      opacity: 0,
      y: 20,
      transition: {
        when: "afterChildren",
      },
    },
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      className="p-5 sm:p-5 h-full"
    >
      <SwifterLogo />

      {/* Capture container */}

      {cameraDetected ? (
        <div className="mt-5 w-[567px] lg:w-[80%] sm:w-full m-auto h-[551px] 2xl:h-[400px] sm:h-full relative">
          <Webcam
            className="w-full h-full object-cover"
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            mirrored={true}
            minScreenshotWidth={1280} 
            minScreenshotHeight={720}
            videoConstraints={videoConstraints}
          />
          <div className="absolute w-full h-full top-0 flex justify-center items-center">
            <img src="images/Vector.png" className="h-[80%]"></img>
          </div>
        </div>
      ) : cameraDetected === false ? (
        <div className="mt-10 w-[25%] lg:w-[80%] sm:w-full m-auto">
          <img src="/images/qr.png" alt="qr code" className="w-full h-full" />
        </div>
      ) : (
        <div className="mt-5 w-[500px] border lg:w-[80%] sm:w-full m-auto h-[500px] 2xl:h-[400px] sm:h-[300px] flex items-center bg-[#171717]">
          <img
            src="/images/placeholder.svg"
            alt="No cam"
            className="w-[60%] block m-auto"
          />
        </div>
      )}
      {/* Capture Button */}
      {cameraDetected === false ? (
        <p className="text-white font-bold text-center uppercase text-4xl 2xl:text-2xl sm:text-[25px] italic mt-5">
          please scan qr code
        </p>
      ) : cameraDetected == null ? (
        <p className="text-white font-bold text-center uppercase text-4xl sm:text-[25px] italic mt-5">
          please allow camera permission
        </p>
      ) : (
        <CustomeButton
          btnText={"capture"}
          btnMainClassName={"w-[200px] sm:h-[60px] h-[70px] 2xl:mt-10"}
          btnMarginTop={"10px"}
          btnBackgroundColor={"white"}
          buttonClassName={"bottom-[5px] right-[5px]"}
          btnTextClassName={"text-2xl sm:text-[20px] text-[#01458E]"}
          clickEvent={handleClick}
          // btnDisabled={cameraDetected === false ? true : cameraDetected === null ? true : false}
          buttonId={"selfie_capture"}
        />
      )}
    </motion.div>
  );
};
