import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Capture } from "../pages/Capture";

import { FormPage } from "../pages/Form";
import { useSelector } from "react-redux";
import { Home } from "../pages/Home";
import { VideoResult } from "../pages/VideoResult";
import { Videos } from "../pages/Videos";
import { Login } from "../pages/login";
import { AuthContext } from "../Hoc/authContext";
import { ImageCaptured } from "../pages/ImageCaptured";
import { Share } from "../pages/Share";

export const RouteswithAuth = () => {
  const isAuthenticated = useSelector((state) => state.capture.isAuthenticated);
  const { authState } = useContext(AuthContext);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route
        path="/capture"
        element={<Capture />}
      />
      <Route
        path="/imageCaptured"
        element={<ImageCaptured />}
      />
      <Route
        path={"/form"}
        element={isAuthenticated ? <FormPage /> : <Navigate to={"/"} />}
      />
      <Route
        path="/video/:token"
        element={<VideoResult />}
      />
      <Route path="/share/:token" element={<Share />} />
      <Route
        path="/videos"
        element={authState.isAuth ? <Videos /> : <Login />}
      />
      <Route path="/login" element={<Login />} />
    </Routes>
  );
};
